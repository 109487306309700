@import 'styles/variables.less';

.br-phone-number-modal__title {
  .font({.display-xs()});
}

.br-phone-number-modal__subtitle {
  color: @text-gray;
}

.br-phone-number-field {
  .br-phone-number-field__input {
    width: 100%;
  }

  .ant-row.ant-form-item-row {
    display: block;

    .ant-input-group.ant-input-group-compact {
      .ant-input-affix-wrapper {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .br-countries-dropdown.ant-select.ant-select-single.ant-select-show-arrow {
    max-height: 36px;
    
    .br-countries-dropdown__country-flag {
      width: 21px;
      margin-right: 10px;
    }

    .ant-select-selector {
      border-radius: 4px 0 0 4px;

      .br-countries-dropdown__country-name,
      .br-countries-dropdown__country-code-number {
        display: none;
      }
    }

    .br-countries-dropdown__option {
      .ant-select-item-option-content {
        display: flex;
        align-items: center;

        .br-countries-dropdown__country-code-name {
          display: none;
        }

        .br-countries-dropdown__country-code-number {
          margin-left: 10px;
          color: @text-gray;
        }
      }
    }
  }
}

html[dir='rtl'] .br-phone-number-modal {
  .br-countries-dropdown.ant-select.ant-select-single.ant-select-show-arrow {
    .ant-select-arrow {
      left: 11px;
    }
    .ant-select-selector {
      border-radius: 0 4px 4px 0;

      .ant-select-selection-item {
        flex-direction: row-reverse;
        padding-left: 18px;
        padding-right: 0;
        .br-countries-dropdown__country-flag {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }

  .br-phone-number-field.ant-form-item {
    .ant-input-group.ant-input-group-compact {
      flex-direction: row-reverse;

      > *:not(:last-child) {
        margin-left: -1px;
        border-left-width: 1px;
      }
    }

    .ant-form-item-control-input-content .ant-input-affix-wrapper {
      border-radius: 4px 0 0 4px;
      flex-direction: row-reverse;
      > span {
        margin-left: 4px;
        margin-right: 0;
      }
      > input {
        text-align: end;
      }
    }
  }
}

@import 'styles/variables.less';

.br-fulfillment__header.br-hero-main {
  padding-top: 0px;
  padding-bottom: 307px;

  & .br__hero__content-svg.svg-1 {
    top: -27px;
    left: unset;
    right: 0;
  }
  & .br-hero__all-pages.br__hero__content-svg.svg-1 svg {
    transform: scaleX(-1);
  }

  & .br-header__title {
    color: @red-600;
    width: 931px;
  }

  & .br-header__subtitle {
    color: @teal-400;
    width: 920px;
  }

  & .br-header__extra {
    & ul {
      margin: 0;
      padding-left: 18px;
      list-style: none;
      margin-bottom: 80px;
      & li {
        margin-bottom: 16px;
      }
      & li::before {
        content: '•';
        color: @red-600;
      }
    }
  }

  & button.ant-btn.ant-btn-primary {
    background-color: @teal-400;
    z-index: 99999;
  }
}

html[dir='rtl'] .br-fulfillment__header.br-hero-main {
  & .br-hero__all-pages.br__hero__content-svg.svg-1 {
    right: 0;
    & svg {
      transform: scaleX(1);
    }
  }
  & .br-header__extra {
    & ul {
      padding-left: 0px;
    }
  }
}

.br-fulfillment__header-second.br-hero-main {
  background-color: @teal-400;
  margin-top: 0;
  padding-bottom: 305px;
  & .br-hero__all-pages.br__hero__content-svg.svg-1 {
    left: 0;

    & svg {
      transform: scaleX(-1);
    }
  }
  & .br-header__title {
    color: white;
    width: 800px;
    font-size: 100px;
    line-height: 10rem;
  }

  & .br-header__subtitle {
    color: white;
    width: 607px;
    font-size: 30px;
    line-height: 3rem;
    text-align: center;
  }
  & .br-hero-header {
    margin-left: 150px;
    width: 100%;
    & .br-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      & .br-header__second-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  & button.ant-btn.ant-btn-primary {
    width: 100%;
    max-width: 337px;
    z-index: 99999;
  }
}

html[dir='rtl'] .br-fulfillment__header-second.br-hero-main {
  & .br-hero__all-pages.br__hero__content-svg.svg-1 {
    left: 0;
    & svg {
      transform: scaleX(1);
    }
  }
  & .br-header__title {
    color: white;
    width: 636px;
    font-size: 100px;
    line-height: 10rem;
  }
}

.br-fulfillment__header-third.br-hero-main {
  padding-top: 0px;
  padding-bottom: 400px;

  margin-top: 0;
  & .br__hero__content-svg.svg-1 {
    top: 140px;
    left: unset;
    right: 0;
  }

  & .br-hero__all-pages.br__hero__content-svg.svg-1 svg {
    transform: scaleX(1);
  }

  & .br-header__title {
    color: @red-600;
    width: 368px;
    font-size: 150px;
    line-height: 10rem;
    padding-top: 100px;
    text-align: center;
  }

  & .br-header__subtitle {
    color: @teal-400;
    width: 609px;
    padding-top: 190px;
    font-size: 33px;
    line-height: 3rem;
    text-align: center;
  }

  & .br-hero-header {
    margin-left: 150px;
    width: 100%;

    & .br-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-right: 300px;
      & .br-header__second-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  & button.ant-btn.ant-btn-primary {
    background-color: @teal-400;
    width: 100%;
    max-width: 337px;
    margin-top: 40px;
    z-index: 99999;
  }
}

html[dir='rtl'] .br-fulfillment__header-third.br-hero-main {
  & .br-hero__all-pages.br__hero__content-svg.svg-1 {
    right: 0;

    & svg {
      transform: scaleX(1);
    }
  }

  & .br-hero-header {
    margin-left: 150px;
    width: 100%;

    & .br-header {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

.br-fulfillment__header-fourth.br-hero-main {
  background-color: @teal-400;
  margin-top: 0;
  padding-bottom: 300px;

  & .br-hero__all-pages.br__hero__content-svg.svg-1 {
    left: 0;
    top: 230px;
    & svg {
      transform: scaleX(1);
    }
  }

  & .br-header__title {
    color: white;
    width: 557px;
    font-size: 150px;
    line-height: 10rem;
    text-align: center;
    & .br-fulfillment-extra-data-in-title {
      font-size: 75px;
    }
  }

  & .br-header__subtitle {
    color: white;
    width: 648px;
    font-size: 35px;
    line-height: 4rem;
    text-align: center;
  }

  & .br-hero-header {
    margin-left: 150px;
    width: 100%;

    & .br-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 150px;
      & .br-header__second-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  & button.ant-btn.ant-btn-primary {
    width: 100%;
    max-width: 337px;
  }
}

.br-fulfillment__header-fifth.br-hero-main {
  padding-top: 0px;
  padding-bottom: 174px;
  margin-top: 0;

  & .br__hero__content-svg.svg-1 {
    top: 50px;
    left: unset;
    right: 300px;
  }

  & .br-hero__all-pages.br__hero__content-svg.svg-1 svg {
    transform: scaleX(1);
  }

  & .br-header__title {
    color: @red-600;
    width: 630px;
    font-size: 130px;
    line-height: 10rem;
    padding-top: 100px;
    text-align: center;
    & .br-fulfillment-extra-data-in-title {
      font-size: 110px;
    }
  }

  & .br-header__subtitle {
    color: black;
    width: 716px;
    padding-top: 190px;
    font-size: 33px;
    line-height: 3rem;
    text-align: center;
  }

  & .br-hero-header {
    margin-left: 150px;
    width: 100%;

    & .br-header {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: space-between;
      padding-right: 300px;

      & .br-header__second-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  & button.ant-btn.ant-btn-primary {
    z-index: 99999;
  }

  & button.ant-btn.ant-btn-primary {
    background-color: @teal-400;
    width: 100%;
    max-width: 337px;
    margin-top: 40px;
  }
}

.br-fulfillment__header-sixth {
  background: @teal-400;
  padding: 150px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  & h1 {
    align-self: center;
    color: white;
    margin-bottom: 50px;
    text-align: center;
  }
  & .br-fulfillment__faq-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  & .br-fulfillment__faq-content-question-content {
    width: 100%;
    max-width: 630px;
    color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    & span:first-child {
      margin-bottom: 8px;
    }
    & span:last-child {
      font-size: 16px;
    }
  }
}

.br-fulfillment__header-seventh {
  height: 100vh;
  background-image: url('../../assets/icons/fulfillment-seventh.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding-top: 100px;
  & div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    & h2 {
      color: @teal-400;
    }
    & h1 {
      color: @red-600;
    }

    & button.ant-btn {
      margin-top: 24px;
      background-color: @teal-400;
    }
    & svg {
      display: none;
    }
  }
}

.zeez {
  height: 90vh;
  background-image: url('../../assets/icons/fulfillment-fifth.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding-top: 100px;

  & div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    & h2 {
      color: @teal-400;
    }

    & h1 {
      color: @red-600;
    }

    & button.ant-btn {
      margin-top: 24px;
      background-color: @teal-400;
    }
    & svg {
      display: none;
    }
  }
}

@media only screen and (max-width: 1655px) {
  .br-fulfillment__header.br-hero-main {
    & .br-header__title {
      width: 600px;
    }
  }
  .br-fulfillment__header-second.br-hero-main {
    & .br__hero__content-svg.svg-1 {
      top: 66px;
    }
    & .br-header__title {
      width: 700px;
      font-size: 80px;
      line-height: 8rem;
    }

    & .br-header__subtitle {
      width: 600px;
      font-size: 25px;
      line-height: 3rem;
    }

    & .br-hero-header {
      & .br-header {
        & .br-header__second-content-container {
          padding-top: 100px;
        }
      }
    }

    & button.ant-btn.ant-btn-primary {
      width: 100%;
      max-width: 337px;
    }
  }
  .br-fulfillment__header-fourth.br-hero-main {
    & .br-hero__all-pages.br__hero__content-svg.svg-1 {
      left: -350px;
      top: 330px;
    }
    & .br-hero-header {
      margin-left: 50px;

      & .br-header {
        gap: 70px;
      }
    }
  }
  .br-fulfillment__header-fifth.br-hero-main {
    padding-top: 0px;
    padding-bottom: 380px;
    margin-top: 0;

    & .br__hero__content-svg.svg-1 {
      top: 125px;
      left: unset;
      right: 0px;
    }

    & .br-header__title {
      width: 730px;
      font-size: 100px;
      line-height: 8rem;
      padding-top: 100px;

      & .br-fulfillment-extra-data-in-title {
        font-size: 70px;
      }
    }

    & .br-header__subtitle {
      width: 416px;
      padding-top: 190px;
      font-size: 33px;
      line-height: 3rem;
    }

    & .br-hero-header {
      margin-left: 80px;
      width: 100%;

      & .br-header {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
        padding-right: 100px;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .br-fulfillment__header.br-hero-main {
    padding-bottom: 239px;
    & .br-header__title {
      width: 600px;
    }
    & .br-header__subtitle {
      width: 600px;
    }
  }
  .br-fulfillment__header-second.br-hero-main {
    padding-bottom: 288px;
    & .br-header__title {
      width: 500px;
      font-size: 70px;
      line-height: 8rem;
    }

    & .br-header__subtitle {
      width: 500px;
      font-size: 25px;
      line-height: 3rem;
    }

    & .br-hero-header {
      & .br-header {
        & .br-header__second-content-container {
          padding-top: 30px;
        }
      }
    }

    & button.ant-btn.ant-btn-primary {
      width: 100%;
      max-width: 337px;
    }
  }
  .br-fulfillment__header-fourth.br-hero-main {
    & .br-header__title {
      font-size: 130px;
    }

    & .br-header__subtitle {
      font-size: 32px;
    }
    & .br-hero__all-pages.br__hero__content-svg.svg-1 {
      left: -450px;
      top: 350px;
    }

    & .br-hero-header {
      margin-left: 0px;

      & .br-header {
        gap: 40px;
      }
    }
  }

  .br-fulfillment__header-fifth.br-hero-main {
    padding-top: 0px;
    padding-bottom: 450px;
    margin-top: 0;

    & .br__hero__content-svg.svg-1 {
      top: 195px;
      left: unset;
      right: -80px;
    }

    & .br-header__title {
      width: 730px;
      font-size: 100px;
      line-height: 8rem;
      padding-top: 100px;

      & .br-fulfillment-extra-data-in-title {
        font-size: 70px;
      }
    }

    & .br-header__subtitle {
      width: 416px;
      padding-top: 190px;
      font-size: 33px;
      line-height: 3rem;
    }

    & .br-hero-header {
      margin-left: 80px;
      width: 100%;

      & .br-header {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
        padding-right: 100px;
      }
    }
  }
  .br-fulfillment__header-sixth {
    & .br-fulfillment__faq-content-container {
      justify-content: unset;
      gap: 30px;
    }
  }
}

@media only screen and (max-width: 1310px) {
  .br-fulfillment__header.br-hero-main {
    & .br-header__title {
      width: 300px;
    }
    & .br-header__subtitle {
      width: 300px;
    }
    & .br-header__extra {
      width: 400px;
    }

    & .br__hero__content-svg.svg-1 {
      top: 150px;
      left: unset;
      right: 0;
    }
  }

  .br-fulfillment__header-second.br-hero-main,
  html[dir='rtl'] .br-fulfillment__header-second.br-hero-main {
    padding-bottom: 360px;
    padding-right: 200px;
    & .br-header__title {
      width: 500px;
      font-size: 60px;
      line-height: 10rem;
    }

    & .br-header__subtitle {
      width: 300px;
      font-size: 20px;
      line-height: 3rem;
    }

    & .br-hero-header {
      & .br-header {
        & .br-header__second-content-container {
          padding-top: 80px;
        }
      }
    }

    & .br-hero__all-pages.br__hero__content-svg.svg-1 {
      top: 120px;
    }

    & button.ant-btn.ant-btn-primary {
      width: 100%;
      max-width: 337px;
    }
  }
  .br-fulfillment__header-third.br-hero-main {
    & .br-header__subtitle {
      color: @teal-400;
      width: 409px;
      padding-top: 190px;
      font-size: 28px;
      line-height: 3rem;
      text-align: center;
    }
  }
  .br-fulfillment__header-fourth.br-hero-main {
    & .br-header__title {
      font-size: 100px;
      line-height: 7rem;
    }

    & .br-header__subtitle {
      font-size: 25px;
      width: 400px;
    }

    & .br-hero__all-pages.br__hero__content-svg.svg-1 {
      left: -750px;
      top: 150px;
    }

    & .br-hero-header {
      margin-left: 0px;

      & .br-header {
        gap: 50px;
      }
    }
  }
  .br-fulfillment__header-fifth.br-hero-main {
    padding-top: 0px;
    padding-bottom: 450px;
    margin-top: 0;

    & .br__hero__content-svg.svg-1 {
      top: 100px;
      left: unset;
      right: -300px;
    }

    & .br-header__title {
      width: 730px;
      font-size: 60px;
      line-height: 6rem;
      padding-top: 100px;

      & .br-fulfillment-extra-data-in-title {
        font-size: 50px;
      }
    }

    & .br-header__subtitle {
      width: 416px;
      padding-top: 190px;
      font-size: 25px;
      line-height: 3rem;
    }

    & .br-hero-header {
      margin-left: 0px;
      width: 100%;

      & .br-header {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
        padding-right: 0px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .br-fulfillment__header.br-hero-main,
  html[dir='rtl'] .br-fulfillment__header.br-hero-main {
    padding-bottom: 50px;
    & .br-header__title {
      width: 300px;
    }

    & .br-header__subtitle {
      width: 200px;
    }

    & .br-header__extra {
      width: 200px;
    }
    & .br__hero__content-svg.svg-1 {
      top: 43px;
      left: 30px;
      right: 0;
    }
  }

  .br-fulfillment__header-second.br-hero-main,
  html[dir='rtl'] .br-fulfillment__header-second.br-hero-main {
    padding-bottom: 520px;
    padding-right: 0;
    & .br__hero__content-svg.svg-1 {
      top: 77px;
    }
    & .br-header__title {
      width: 300px;
      font-size: 50px;
      line-height: 5rem;
      align-self: center;
    }

    & .br-header__subtitle {
      width: 400px;
      font-size: 18px;
      line-height: 2.5rem;
    }

    & .br-hero-header {
      & .br-header {
        & .br-header__second-content-container {
          padding-top: 80px;
        }
      }
    }

    & button.ant-btn.ant-btn-primary {
      width: 100%;
      max-width: 337px;
    }
  }
  .br-fulfillment__header-third.br-hero-main {
    & .br-header__title {
      width: 268px;
      font-size: 100px;
      line-height: 8rem;
      padding-top: 100px;
    }
    & .br-header__subtitle {
      width: 309px;
      font-size: 23px;
    }
  }
  .br-fulfillment__header-fourth.br-hero-main {
    & .br-header__title {
      font-size: 100px;
      line-height: 7rem;
    }

    & .br-header__subtitle {
      font-size: 25px;
      width: 400px;
      line-height: 3rem;
    }

    & .br-hero__all-pages.br__hero__content-svg.svg-1 {
      left: -790px;
      top: 150px;
    }

    & .br-hero-header {
      margin-left: 0px;

      & .br-header {
        gap: 50px;
      }
    }
  }
  .br-fulfillment__header-fifth.br-hero-main {
    padding-top: 0px;
    padding-bottom: 400px;
    margin-top: 0;

    & .br__hero__content-svg.svg-1 {
      top: 12px;
      left: unset;
      right: -380px;
    }

    & .br-header__title {
      width: 730px;
      font-size: 60px;
      line-height: 7rem;
      padding-top: 150px;

      & .br-fulfillment-extra-data-in-title {
        font-size: 50px;
      }
    }

    & .br-header__subtitle {
      width: 416px;
      padding-top: 200px;
      font-size: 20px;
      line-height: 3rem;
    }

    & .br-hero-header {
      margin-left: 0px;
      width: 100%;

      & .br-header {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
        padding-right: 0px;
      }
    }
  }

  .br-fulfillment__header-sixth {
    & .br-fulfillment__faq-content-container {
      justify-content: unset;
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .br-fulfillment__header.br-hero-main,
  html[dir='rtl'] .br-fulfillment__header.br-hero-main {
    padding: 0 30px;
    & .br-header__title {
      width: unset;
    }

    & .br-header__subtitle {
      width: unset;
    }

    & .br-header__extra {
      width: unset;
    }
    .br__hero__content-svg {
      max-width: 100vw;
      &.svg-1 {
        right: unset;
        top: unset;
        left: 0;
        bottom: -165px;

        & > svg {
          max-width: 100%;
        }
      }
    }
  }
  .br-fulfillment__header-second.br-hero-main,
  html[dir='rtl'] .br-fulfillment__header-second.br-hero-main {
    padding-bottom: 0px;

    & .br-header__title {
      width: unset;
      font-size: 45px;
      line-height: 69px;
    }

    & .br-header__subtitle {
      width: unset;
      font-size: 20px;
      line-height: 28px;
    }

    & .br-hero-header {
      margin-left: 0;
      margin-top: 80px;
      padding-top: 50px;
      & .br-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        & .br-header__second-content-container {
          padding-top: 0px;
        }
      }
    }

    & button.ant-btn.ant-btn-primary {
      width: unset;
      //   max-width: unset;
    }

    .br__hero__content-svg {
      max-width: 100vw;

      &.svg-1 {
        right: unset;
        top: unset;
        left: 0;
        bottom: -50px;

        & > svg {
          max-width: 100%;
        }
      }
    }
  }

  .br-fulfillment__header-third.br-hero-main,
  html[dir='rtl'] .br-fulfillment__header-third.br-hero-main {
    padding-bottom: 0px;
    & .br-header__title {
      width: unset;
      font-size: 50px;
      line-height: 68px;
      padding-top: unset;
    }

    & .br-header__subtitle {
      width: unset;
      font-size: 20px;
      line-height: 28px;
      padding-top: unset;
    }

    & .br-hero-header {
      margin-left: unset;
      width: 100%;
      padding-top: 50px;
      & .br-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-right: unset;
      }
    }

    .br__hero__content-svg {
      max-width: 100vw;

      &.svg-1 {
        right: unset;
        top: unset;
        left: 0;
        bottom: -100px;

        & > svg {
          max-width: 100%;
        }
      }
    }
  }

  .br-fulfillment__header-fourth.br-hero-main {
    padding-bottom: 0px;
    padding-top: 20px;
    & .br-header__title {
      width: unset;
      font-size: 45px;
      line-height: 69px;
      display: flex;
      & .br-fulfillment-extra-data-in-title {
        font-size: 45px;
        margin: 0 10px;
      }
    }

    & .br-header__subtitle {
      width: unset;
      font-size: 20px;
      line-height: 28px;
    }

    & .br-hero-header {
      margin-left: 0;
      margin-top: 80px;
      padding-top: 50px;
      align-items: center;
      & .br-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        & .br-header__second-content-container {
          padding-top: 0px;
        }
      }
    }

    & .br__hero__content-svg.br-hero__all-pages {
      max-width: 100vw;

      &.svg-1 {
        right: unset;
        top: unset;
        left: -30px;
        bottom: 60px;
        & > svg {
          max-width: 100%;
        }
      }
    }
  }

  .br-fulfillment__header-fifth.br-hero-main {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    & .br__hero__content-svg.br-hero__all-pages {
      max-width: 100vw;

      &.svg-1 {
        right: unset;
        top: 600px;
        left: -10px;
        bottom: 0px;
        & > svg {
          max-width: 100%;

          height: 500px;
        }
      }
    }

    & .br-header__title {
      width: unset;
      font-size: 50px;
      line-height: 64px;
      padding-top: 150px;

      & .br-fulfillment-extra-data-in-title {
        font-size: 50px;
        width: unset;
        line-height: 64px;
      }
    }

    & .br-header__subtitle {
      width: unset;
      padding-top: 100px;
      font-size: 20px;
      line-height: 28px;
    }

    & .br-hero-header {
      margin-left: 0px;
      width: 100%;

      & .br-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-right: 0px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .br-fulfillment__header-second.br-hero-main,
  html[dir='rtl'] .br-fulfillment__header-second.br-hero-main {
    padding: 0px 15px;
  }

  .br-fulfillment__header-fourth.br-hero-main {
    padding-bottom: 0px;
    padding-top: 20px;

    & .br-header__title {
      width: unset;
      font-size: 45px;
      line-height: 69px;
      display: unset;

      & .br-fulfillment-extra-data-in-title {
        font-size: 45px;
        margin: 0 10px;
      }
    }

    & .br-hero-header {
      margin-left: 0;
      margin-top: 80px;
      padding-top: 50px;
      align-items: center;

      & .br-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        & .br-header__second-content-container {
          padding-top: 0px;
        }
      }
    }

    .br__hero__content-svg.br-hero__all-pages {
      max-width: 100vw;

      &.svg-1 {
        right: unset;
        top: unset;
        left: -30px;
        bottom: -200px;

        & > svg {
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .br-fulfillment__header.br-hero-main,
  html[dir='rtl'] .br-fulfillment__header.br-hero-main {
    padding-bottom: 0px;
    .br__hero__content-svg {
      max-width: 100vw;

      &.svg-1 {
        right: unset;
        top: unset;
        left: 0;
        bottom: -280px;

        & > svg {
          max-width: 100%;
        }
      }
    }

    & .br-header__title {
      width: unset;
      font-size: 45px;
      line-height: 69px;
    }

    & .br-header__subtitle {
      width: unset;
    }
    & .br-header__extra {
      width: unset;
    }
  }

  .br-fulfillment__header-second.br-hero-main,
  html[dir='rtl'] .br-fulfillment__header-second.br-hero-main {
    padding: 0px 15px;

    & .br-header__title {
      width: unset;
      font-size: 45px;
      line-height: 69px;
    }

    & .br-header__subtitle {
      width: unset;
      font-size: 20px;
      line-height: 28px;
    }

    & .br-hero-header {
      margin-left: 0;
      margin-top: 0px;
      padding-top: 50px;

      & .br-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        & .br-header__second-content-container {
          padding-top: 0px;
        }
      }
    }

    & button.ant-btn.ant-btn-primary {
      width: unset;
      //   max-width: unset;
    }

    .br__hero__content-svg {
      max-width: 100vw;

      &.svg-1 {
        right: unset;
        top: unset;
        left: 0;
        bottom: -298px;

        & > svg {
          max-width: 100%;
        }
      }
    }
  }

  .br-fulfillment__header-third.br-hero-main,
  html[dir='rtl'] .br-fulfillment__header-third.br-hero-main {
    padding-bottom: 0px;

    .br__hero__content-svg {
      max-width: 100vw;

      &.svg-1 {
        right: unset;
        top: unset;
        left: 0;
        bottom: -340px;

        & > svg {
          max-width: 100%;
        }
      }
    }
  }

  .br-fulfillment__header-fourth.br-hero-main {
    padding-bottom: 0px;
    padding-top: 20px;

    & .br-header__title {
      width: unset;
      font-size: 45px;
      line-height: 69px;
      display: unset;

      & .br-fulfillment-extra-data-in-title {
        font-size: 45px;
        margin: 0 10px;
      }
    }

    & .br-hero-header {
      margin-left: 0;
      margin-top: 80px;
      padding-top: 50px;
      align-items: center;

      & .br-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        & .br-header__second-content-container {
          padding-top: 0px;
        }
      }
    }

    .br__hero__content-svg.br-hero__all-pages {
      max-width: 100vw;

      &.svg-1 {
        right: unset;
        top: unset;
        left: -30px;
        bottom: -200px;

        & > svg {
          max-width: 100%;
        }
      }
    }
  }

  .br-fulfillment__header-fifth.br-hero-main {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;

    & .br__hero__content-svg.br-hero__all-pages {
      max-width: 100vw;

      &.svg-1 {
        right: unset;
        top: 920px;
        left: -10px;
        bottom: 0px;

        & > svg {
          max-width: 100%;

          height: 200px;
        }
      }
    }
  }

  .br-fulfillment__header-sixth {
    padding: 30px;
    padding-top: 100px;
    & .br-fulfillment__faq-content-container {
      flex-direction: column;
    }
  }

  .zeez {
    background-image: none;
    & div svg {
      display: block;
      height: 200px;
    }
  }

  .br-fulfillment__header-seventh {
    background-image: none;
    height: 100%;
    & div svg {
      display: block;
      height: 160px;
    }
  }
}

@import 'styles/variables.less';

.br-date-address-edit {
  width: 100%;
  max-width: 856px;
  display: flex;
  flex-direction: column;
  text-align: start;
  border-radius: 8px;
  margin-bottom: 24px;
  box-shadow: @shadow-sm;
  padding: 16px;
  gap: 16px;
}

.br-date-address-edit__title {
  color: @text-gray;
}

.br-date-address-edit__data-show {
  display: flex;
  gap: 24px;

  &.show-data .data-show__data__value {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .data-show__data__hidden-address {
    display: flex;
    align-items: center;
    gap: 9px;
  }

  .data-show__vertical-divider {
    border-right: 2px solid @gray-200;
    height: auto;
  }

  .data-show__data__key {
    color: @text-gray;
    margin-bottom: 4px;
    text-transform: uppercase;
    .font({.subheading()});
  }

  .data-show__data__value {
    .font({.body-medium()});
    .br-button-component {
      margin-top: 16px;
      gap: 6px;
    }
  }
}

.br-date-address-edit__identity-verification {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  background-color: @teal-100;
  border: 1px solid @teal-300;
  border-radius: 4px;

  svg path {
    fill: @icon-teal;
  }

  .br-date-address-edit__identity-verification-content {
    .ant-btn {
      margin-top: 12px;
    }
  }
}

.data-show__data__hidden-address__tooltip .ant-tooltip-inner {
  max-width: 215px;
}

@media only screen and (max-width: @xs) {
  .br-date-address-edit__data-show {
    flex-direction: column;

    &.show-data .data-show__data__value {
      display: block;
      height: auto;
    }
  }

  .data-show__vertical-divider {
    border-top: 2px solid @gray-200;
  }
}

@import 'styles/variables.less';

.br-shipment-tracking.br-hero-main {
  margin-top: 0;
  padding: 0;
  height: 100%;

  &.br-shipment-tracking__empty-state {
    height: calc(~'100vh - 68px');
  }
}

.br-tracking__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px 16px 0 16px;
  background-color: @bg-teal-light;
  border-radius: 8px;
}

.br-tracking__header-subtitle {
  margin-top: 16px;
}

.br-shipment-tracking__empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .br-shipment-tracking__details__tracking-number {
    margin-bottom: 24px;
    margin-top: 70px;
  }
  .br-shipment-tracking-track-input-container {
    padding-top: 34px;
    background-color: @bg-teal-light;
  }
}

.br-shipment-tracking__empty-data__alert {
  display: flex;
  align-items: baseline;
  text-align: start;
  padding: 16px;
  gap: 12px;
  max-width: 609px;
  background: @red-50;
  border: 1px solid @red-200;
  border-radius: 4px;
  margin: 16px;
}

.br-shipment-tracking__tracking-system-feature {
  max-width: 580px;
  width: 100%;
  margin-bottom: 50px;
  & h4,
  p {
    color: @gray-600;
  }

  & p {
    margin-bottom: 0px;
  }
}

.br-shipment-tracking .br-shipment-tracking__svgs {
  min-width: 50%;
  div {
    position: absolute;
  }
  .svg-1 {
    left: 0;
    bottom: 190px;
    z-index: 3;
  }
  .svg-2 {
    left: 106px;
    bottom: 190px;
    z-index: 4;
  }
  .svg-3 {
    left: 106px;
    bottom: 292px;
    z-index: 2;
  }
  .svg-4 {
    left: 230px;
    bottom: 190px;
    z-index: 6;
  }
  .svg-5 {
    left: 399px;
    bottom: 189px;
    z-index: 5;
  }
  .svg-6 {
    left: 80px;
    bottom: 357px;
    z-index: 1;
    width: 309px;
    height: 204;
  }
  .svg-7 {
    left: 410px;
    bottom: 381px;
    z-index: 1;
  }
  .svg-8 {
    left: 174px;
    bottom: 41px;
  }
}

html[dir='rtl'] .br-shipment-tracking__svgs div {
  transform: scaleX(-1);
}

.br-shipment-tracking__ads-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(/src/assets/images/tracking-ads-background.svg);
  background-repeat: no-repeat;
  background-position: top;
}

.br-shipment-tracking-track-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  & h4 {
    color: @text-gray-dark;
    margin-bottom: 24px;
  }
  .ant-input-group-wrapper {
    display: flex;
    justify-content: center;
  }
  & .ant-input-search.ant-input-search-with-button .ant-input-group {
    max-width: 455px;
    width: 100%;
    box-shadow: @shadow-md;
    border-radius: 10px;

    & input.ant-input {
      width: 100%;
      height: 60px;
      border: 1px solid @gray-200;
      border-radius: 10px 0px 0px 10px;
      padding: 20px;
    }
    & .ant-input-group-addon {
      &,
      button {
        border-radius: 0px 10px 10px 0px;
      }
      button {
        width: 60px;
        height: 60px;
        & svg {
          font-size: 26px;
        }
      }
    }
  }

  .br-shipment-tracking__tracking-number-field {
    background: linear-gradient(to top, @white 50%, @bg-teal-light 50%);
    padding: 0 16px;
  }
}

.br-shipment-details-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 312px;
  background-color: white;

  .br-shipment-tracking__details {
    margin-top: 24px;
  }
}

.br-shipment-tracking__details--header,
.br-shipment-tracking__details--divider {
  margin-top: 24px;
}

.br-shipment-tracking__details--timeline__title,
.br-shipment-tracking__details--divider {
  margin-bottom: 32px;
}

.br-shipment-tracking__details--timeline__title {
  .font({.display-sm()});
}

.br-shipment-tracking__details--update-date {
  margin-top: 8px;
}

.br-shipment-tracking__details--header__subtitle {
  color: @text-gray;
  margin-top: 4px;
  display: flex;
  gap: 4px;

  span {
    color: @text-teal;
  }
}

.br-shipment-tracking__details--divider {
  background: @gray-200;
  width: 856px;
  height: 1px;
}

.br-shipment-tracking__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  border-radius: 8px;
  box-shadow: @shadow-sm;
  width: 100%;
  max-width: 856px;
  margin-bottom: 24px;

  .br-shipment-tracking-details__header {
    padding: 16px;
    width: 100%;
  }

  .br-shipment-tracking-details__timeline {
    box-shadow: @border-top;
    padding: 36px 16px 16px 16px;
    width: 100%;
  }
}

.br-shipment-tracking__details--timeline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 856px;
}

.br-shipment-tracking__details--logs {
  max-height: 828px;
  overflow: hidden;
  text-align: start;
}

.br-shipment-tracking__details--logs__expaned {
  max-height: 100%;
}

.br-shipment-tracking__details--logs__overflowed {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0.1))
  );
}

.br-shipment-details__expand-collapse__down-arrow {
  transform: rotate(180deg);
}

.br-shipment-tracking__details--mobile-details {
  margin-top: 4px;

  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .br-shipment-tracking__details--divider {
    margin-top: 16px;
  }
  .br-shipment-tracking__details--mobile-details-date {
    margin-bottom: 32px;
  }
}

.br-shipment-tracking__details__tracking-number,
.br-shipment-tracking__details--update-date,
.br-shipment-tracking__details--timeline__title,
.br-shipment-details_tracking-details-date,
.br-shipment-tracking__details--mobile-details span:first-child {
  color: @text-gray;
}

.br-shipment-tracking__details__tracking-number {
  text-transform: uppercase;
}

.br-tracking-arrival__date {
  color: @text-teal;
}

.br-tracking-alert-icon {
  svg path {
    fill: @icon-subdued;
  }
}

.br-shipment-tracking__details-show-more {
  max-width: 856px;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.br-shipment-tracking__details-contact-business {
  color: @text-teal;
  margin-top: 8px;
}

.br-shipment-tracking__footer {
  height: 180px;
  position: absolute;
  inset: auto 0 0;
  background-image: url(/src/assets/images/TrackingFooter.png);
  background-size: contain;
  background-repeat: repeat-x;
  opacity: 0.5;
}

.br-shipment-tracking__valu-banner-container {
  height: 150px;
  padding: 12px;
  display: flex;
  justify-content: center;
  background-color: @white;
  margin: 12px 0 0 0;
}

.br-shipment-tracking__valu-banner {
  text-align: start;
  display: flex;
  width: 100%;
  max-width: 856px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  box-shadow: @shadow-sm;

  > img {
    height: 100%;
    max-width: 196px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 12px;

    > img {
      max-height: 44px;
      max-width: 196px;
      margin-bottom: 12px;
    }

    > p {
      margin: 0;
    }
  }
}
html[dir='rtl'] .br-shipment-tracking__valu-banner {
  > img {
    transform: rotateY(180deg);
  }
}

@media only screen and (max-width: 991px) {
  .br-shipment-pricing-info-section {
    padding: 0 30px;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 40px;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  .br-shipment-tracking__tracking-system-feature {
    max-width: 100%;
  }

  .br-ads-section__container {
    flex-direction: column;
    padding: 0 30px;
    max-width: 100%;
    max-height: 100%;

    & > svg {
      height: 100%;
      width: 100%;
    }
  }

  .br-ads-section__text-container {
    text-align: center;
    max-width: 100%;
  }

  .br-shipment-tracking {
    & .br-hero-header {
      flex-direction: column;
    }
  }

  .br-shipment-tracking .br-pricing-info-section {
    gap: 0;
  }
}

@media only screen and(max-width: @sm) {
  .br-shipment-tracking__valu-banner {
    > div > img {
      max-width: 92px;
    }
    > img {
      max-width: 138px;
      height: auto;
    }
  }

  .br-shipment-details-section {
    display: block;
    margin-top: 16px;
    padding: 16px;
    padding-bottom: 175px;

    .br-shipment-tracking__details {
      margin-top: 0px;
    }
  }

  .br-shipment-tracking__empty-data {
    padding-top: 16px;
  }

  .br-shipment-tracking__details--header__subtitle {
    margin-top: 4px;
  }

  .br-shipment-details__expand-collapse {
    margin: auto;
  }

  .br-shipment-tracking__details--mobile-details,
  .br-shipment-tracking__details--timeline {
    text-align: start;
  }

  .br-shipment-tracking.br-hero-main {
    .br-pricing-info-section {
      margin-top: 16px;
      padding: 16px;
    }
  }

  .br-shipment-tracking__details--divider {
    margin: 16px 0;
  }

  .br-shipment-tracking__details--timeline {
    max-width: 100%;
  }

  .br-shipment-tracking .br-shipment-tracking__svgs {
    display: none;
  }

  .br-shipment-tracking__footer {
    background-position: center;
    height: 90px;
    margin-top: 85px;
  }
}

.br-shipment-tracking-image {
  margin-top: 200px;
  width: 550px;
}

html[dir='rtl'] .br-shipment-tracking-image {
  transform: scaleX(-1);
}

@import 'styles/variables.less';

.br-fulfillment__stats-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 96px 64px;
  gap: 80px;

  p {
    margin: 0;
  }

  .bosta_logo {
    max-width: 124px;
    width: 100%;
    height: auto;
    object-fit: contain;
    align-self: center;
  }

  .br-fulfillment__stats-intro {
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: center;

    p {
      padding: 0 250px;
      .display-md();
    }
  }

  .br-fulfillment__stats-content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;
    white-space: nowrap;

    .br-fulfillment__stats-headline {
      text-align: center;
      .display-lg();
    }

    .br-fulfillment__stats-grid {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 32px;

      .br-fulfillment__stats-card {
        display: flex;
        flex-direction: column;
        max-height: 128px;
        width: 230px;
        gap: 16px;
        padding: 24px;
        align-items: center;
        justify-content: center;
        border-radius: 16px 16px 0px 0px;
        border-bottom: 3px solid @red-600;
        background: @white;
        box-shadow: @shadow-md;
        text-align: center;

        .stats-value {
          color: @red-600;
          .display-lg();
        }

        .stats-title {
          .display-sm();
        }
      }
    }
  }
}

@media only screen and (max-width: @lg) {
  .br-fulfillment__stats-section {
    .br-fulfillment__stats-intro {
      p {
        padding: 0 100px;
        .display-md();
      }

      .bosta_logo {
        max-width: 100px;
      }
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-fulfillment__stats-section {
    gap: 40px;
    padding: 40px 16px;

    .br-fulfillment__stats-intro {
      gap: 24px;

      p {
        padding: 0 15px;
        .heading();
      }

      .bosta_logo {
        max-width: 86px;
      }
    }

    .br-fulfillment__stats-content {
      display: flex;
      gap: 24px;
      padding: 0 10.5px;

      .br-fulfillment__stats-grid {
        gap: 16px;

        .br-fulfillment__stats-card {
          gap: 8px;
          padding: 16px 16px;
          width: 140px;
          flex-grow: 1;

          .stats-value {
            color: @red-600;
            .display-xs();
          }

          .stats-title {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }
}

@import './fonts-variables.less';
//font-styles

.font-9 {
  font-size: 9px;
  line-height: 14px;
  letter-spacing: 0;
}

.font-10 {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0;
}

.font-12 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
}

.font-13 {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0;
}

.font-13-modified {
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0;
}

.font-14 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
}

.font-14-modified {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
}

.font-15 {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0;
}

.font-16 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

.font-16-lg {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0;
}

.font-16-modified {
  font-size: 16px;
  line-height: 47px;
  letter-spacing: 0;
}

.font-17 {
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0;
}

.font-18 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
}

.font-20 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
}

.font-20-modified {
  font-size: 20px;
  line-height: 34px;
  letter-spacing: 0;
}

.font-21 {
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 0;
}

.font-21-sm {
  font-size: 21px;
  line-height: 27px;
  letter-spacing: 0;
}

.font-22 {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0;
}

.font-22-modified {
  font-size: 22px;
  line-height: 36px;
  letter-spacing: 0;
}

.font-23 {
  font-size: 23px;
  line-height: 37px;
  letter-spacing: 0;
}

.font-24 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
}

.font-25 {
  font-size: 25px;
  line-height: 41px;
  letter-spacing: 0;
}

.font-28 {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0;
}

.font-30 {
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0;
}

.font-32 {
  font-size: 32px;
  line-height: 51.2px;
  letter-spacing: 0;
}

.font-35 {
  font-size: 35px;
  line-height: 64px;
  letter-spacing: 0;
}

.font-40 {
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 0;
}

.font-45 {
  font-size: 45px;
  line-height: 64px;
  letter-spacing: 0;
}

.font-50 {
  font-size: 50px;
  line-height: 69px;
}

.font-50-lg {
  font-size: 50px;
  line-height: 96px;
}

.font-60 {
  font-size: 60px;
  line-height: 96px;
}

//text styles
.signup-h1 {
  .font-60;
  .responsive(@sm,{
    .font-21;
  });
}

.signup-h1-2 {
  .font-60;
  .responsive(@sm,{
    .font-25;
  });
}

.signup-h2 {
  .font-45;
  .responsive(@sm,{
    .font-14-modified;
  });
}

.signup-xl {
  .font-45;
  .responsive(@sm,{
    .font-21-sm;
  });
}

.signup-header {
  .font-35;
  .responsive(@sm,{
    .font-16-modified;
  });
}

.signup-header-2 {
  .font-35;
  .responsive(@sm,{
    .font-23;
  });
}

.display-xl {
  .font-40;
  .responsive(@sm,{
    .font-28;
  });
}

.display-lg {
  .font-28;
  .responsive(@sm,{
    .font-24
  });
}

.display-lg-extra {
  .font-32;
}

.display-md {
  .font-24;
  .responsive(@sm,{
    .font-20;
  });
}

.signup-md {
  .font-24;
  .responsive(@sm,{
    .font-16-lg
  });
}

.display-sm {
  .font-20;
  .responsive(@sm,{
    .font-16;
  });
}

.signup-sm {
  .font-20;
  .responsive(@sm,{
    .font-13-modified
  });
}

.display-xs {
  .font-18;
}

.sidebar-child-medium,
.sidebar-child,
.caption,
.caption-medium {
  .font-12;
  .responsive(@sm,{
    .font-13;
  });
}

.subtitle-sm {
  .font-14;
}

.heading,
.button-lg,
.subtitle-md {
  .font-16;
  .responsive(@sm,{
    .font-17;
  });
}

.subtitle-lg {
  .font-20;
  .responsive(@sm,{
    .font-16;
  });
}

.subheading {
  .font-12;
  letter-spacing: 0.04em;
  .responsive(@sm,{
    letter-spacing: 4%;
    .font-13;
  });
}

.signup-medium {
  .font-10;
}

.contact-subtitle {
  .font-10;
}

.button,
.body-medium,
.body,
.sidebar-parent {
  .font-14;
  .responsive(@sm ,{
   .font-15;
  });
}

.caption-lg {
  .font-13;
  .responsive(@sm ,{
    .font-14;
   });
}

.title-sm {
  .font-17;
  .responsive(@sm ,{
    .font-15;
   });
}

.table-title {
  .font-22;
  .responsive(@sm,{
    .font-17
  });
}

.signup-text {
  .font-22-modified;
  .responsive(@sm,{
    .font-9
  });
}

.header-title {
  .font-50;
  .responsive(@sm,{
    .font-25;
  });
}

.signup-title {
  .font-50-lg;
  .responsive(@sm,{
    .font-20-modified;
  });
}

.ads-heading {
  .font-30;
  .responsive(@sm,{
    .font-24;
  });
}

.heading-sm,
.grid-title,
.heading-lg-2,
.text-lg-normal {
  .font-30;
  .responsive(@sm,{
    .font-17;
  });
}

.md-spans,
.carousel_headings,
.carousel_subheading,
.navbar-link,
.navbar-input-title,
.button-primary {
  .font-16;
}

//configuration related to dashboard language
.display-lg,
.display-xl,
.signup-header,
.signup-header-2,
.signup-h1,
.signup-h1-2,
.signup-h2,
.signup-title,
.signup-medium,
.signup-md,
.heading-lg-2,
.display-lg-extra {
  font-family: @arabic-bold-english-semi-bold;
}

.display-md,
.display-sm,
.signup-xl,
.table-title {
  font-family: @font-semibold-1;
}

.sidebar-parent,
.grid-title {
  font-family: @arabic-semibold-english-medium;
}

.sidebar-child-medium,
.signup-sm,
.display-xs {
  font-family: @font-medium;
}

.sidebar-child,
.subtitle-md,
.text-lg-normal,
.subtitle-sm,
.subtitle-lg {
  font-family: @font-regular-1;
}

.heading,
.button-lg,
.button,
.body-medium,
.button,
.caption-medium {
  font-family: @font-semibold-2;
}

.subheading,
.body,
.caption-lg,
.caption,
.md-spans,
.carousel_subheading,
.navbar-input-title,
.signup-text,
.navbar-input-title,
.contact-subtitle {
  font-family: @font-regular-2;
}

.display-xs,
.carousel_headings,
.ads-heading,
.navbar-link {
  font-family: @font-bold-1;
}

.header-title,
.button-primary {
  font-family: @font-bold-2;
}

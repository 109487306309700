@import 'styles/variables';

.br-otp-modal__container .br-content-header-with-sub-title {
  margin-bottom: 17px;
}

.br-otp-validation__receive-confirmation {
  color: @text-gray-dark;
}

.br-opt-modal__form-text .br-otp-code__resend-code-link,
.br-opt-modal__form-text .br-otp-code__resend-code-link-disabled {
  display: inline;
  padding: 0px;
}

.br-otp-code__resend-code-link span,
.br-otp-code__resend-code-link-disabled span {
  font-size: 13px;
  text-decoration: underline;
}

.br-otp-validation__receive-confirmation__timer,
.br-otp-modal__code-sent__phone {
  color: @text-gray;
}

html[dir='rtl'] .br-otp-modal__code-sent__phone {
  direction: rtl;
  unicode-bidi: embed;
}

.br-otp-modal__otp-code .br-otp-modal__header .br-otp-modal__code-sent {
  display: block;
  color: @text-gray-dark;
}

.br-otp-modal__otp-code .br-otp-modal__header .ant-btn {
  display: inline;
}

.br-otp-validation__content
  .ant-form-item-has-error
  .ant-form-item-control-input-content {
  box-shadow: none;
  input {
    box-shadow: 0 0 0 3px @red-100;
  }
}

.br-otp-code__resend-code-link span {
  color: @teal-500;
}

.br-otp-code__resend-code-link-disabled span {
  // color: @text-color-32;
}

.ant-btn.br-otp-code__resend-code-link {
  padding: 0;
}

.br-otp-modal__container .ant-modal-close svg {
  font-size: 17px;
  // color: @text-color-34;
}

.br-otp-modal__container
  .ant-form-item:not(:last-child)
  .ant-form-item-control-input-content::after {
  padding: 4px;
  content: '-';
}

.br-opt-modal__error-text {
  text-align: center;
  color: @red-500;
}

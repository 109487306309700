@import 'styles/variables.less';

.br-fulfillment-form__container {
  padding-top: 0;
  & .ant-form {
    & .ant-form-item {
      display: flex;
      & .ant-row.ant-form-item-row {
        flex-direction: column;
        align-items: flex-start;
        width: 568px;

        & .ant-col.ant-form-item-control {
          margin-top: 8px;
          width: 100%;
          & .ant-form-item-control-input {
            width: 100%;
            & input {
              border-radius: 8px;
              width: 100%;
            }
          }

          & .ant-radio-inner,
          & .ant-checkbox-inner {
            border-radius: 29%;
          }
        }
      }
    }
  }
}

html[dir='rtl']
  .br-fulfillment-form__container
  .ant-form
  .ant-select.ant-select-in-form-item.ant-select-single.ant-select-show-arrow
  .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  left: 0;
}

.br-fulfillment-form__first-section-container {
  height: 100vh;
  width: 100vw;
  background-image: url('../../../../assets/icons/fulfillment-form-1.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 300px;
  & .br-fulfillment-form__first-section-text-container {
    text-align: center;
    width: 100%;
    max-width: 648px;
    & h1 {
      color: @teal-400;
      width: 100%;
      font-size: 100px;
    }
    & h2 {
      color: @red-600;
      font-size: 70px;
      margin-bottom: 40px;
    }

    & h4 {
      font-size: 40px;
      line-height: 3rem;
      margin-bottom: 64px;
    }

    & svg {
      width: 500px;
      height: 60px;
    }
  }
  & .br-fulfillment-form__first-section-form-container {
    padding-top: 20px;
    & .ant-form-item {
      margin-bottom: 40px;
    }
  }
}

.br-fulfillment-form__container
  .br-fulfillment-form__radio-and-checkbox-container {
  height: 100vh;
  width: 100vw;
  background-image: url('../../../../assets/icons/fulfillment-form-2.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #ebf9fa;
  display: flex;
  gap: 100px;
  padding: 50px 150px;
  padding-bottom: 0;

  & .br-fulfillment-form__checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    & button.ant-btn {
      margin-top: 32px;
      width: 100%;
    }
  }

  & .ant-form-item {
    & .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      & .ant-checkbox-wrapper {
        margin: 0;
        margin-bottom: 8px;
        & .ant-checkbox-checked .ant-checkbox-inner::after {
          transform: rotate(150deg) scaleY(-1) scaleX(1) translate(-50%, -50%);
        }
      }
    }

    & .ant-col.ant-form-item-control {
      & .ant-radio-group {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        & .ant-radio-wrapper {
          margin: 0;
          margin-bottom: 8px;
        }
      }
    }

    &
      .ant-row.ant-form-item-row
      .ant-col.ant-form-item-control
      .ant-form-item-control-input
      .ant-form-item-control-input-content
      input {
      width: 40%;
    }
  }
}

html[dir='ltr'] .br-fulfillment-form__container {
  direction: rtl;
}

@media only screen and (max-width: 1655px) {
  .br-fulfillment-form__first-section-container {
    padding: 100px;
    padding-right: 300px;
    & .br-fulfillment-form__first-section-text-container {
      text-align: center;
      width: 100%;
      max-width: 648px;

      & h1 {
        width: 100%;
        font-size: 100px;
        line-height: 6rem;
      }

      & h2 {
        font-size: 70px;
        margin-bottom: 40px;
      }

      & h4 {
        font-size: 40px;
        line-height: 3.5rem;
        margin-bottom: 40px;
      }

      & svg {
        width: 500px;
        height: 50px;
      }
    }

    & .br-fulfillment-form__first-section-form-container {
      padding-top: 20px;

      & .ant-form-item {
        margin-bottom: 40px;
      }
    }
  }
  .br-fulfillment-form__container
    .br-fulfillment-form__radio-and-checkbox-container {
    gap: 0px;
    & .br-fulfillment-form__checkbox-container {
      & button.ant-btn {
        margin-top: 32px;
        width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .br-fulfillment-form__container {
    & .ant-form {
      & .ant-form-item {
        & .ant-row.ant-form-item-row {
          width: 368px;
        }
      }
    }
  }

  .br-fulfillment-form__first-section-container {
    padding: 100px;
    padding-right: 100px;

    & .br-fulfillment-form__first-section-text-container {
      text-align: center;
      width: 100%;
      max-width: 648px;

      & h1 {
        font-size: 100px;
        line-height: 6rem;
      }

      & svg {
        width: 500px;
        height: 60px;
      }
    }

    & .br-fulfillment-form__first-section-form-container {
      padding-top: 20px;

      & .ant-form-item {
        margin-bottom: 40px;
      }
    }
  }

  .br-fulfillment-form__container
    .br-fulfillment-form__radio-and-checkbox-container {
    gap: 50px;
    padding: 50px 100px;
    & .br-fulfillment-form__checkbox-container {
      & button.ant-btn {
        margin-top: 32px;
        width: 60%;
      }
    }

    & .ant-form-item {
      & .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        & .ant-checkbox-wrapper {
          margin: 0;
          margin-bottom: 8px;

          & .ant-checkbox-checked .ant-checkbox-inner::after {
            transform: rotate(150deg) scaleY(-1) scaleX(1) translate(-50%, -50%);
          }
        }
      }

      & .ant-col.ant-form-item-control {
        & .ant-radio-group {
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          & .ant-radio-wrapper {
            margin: 0;
            margin-bottom: 8px;
          }
        }
      }

      &
        .ant-row.ant-form-item-row
        .ant-col.ant-form-item-control
        .ant-form-item-control-input
        .ant-form-item-control-input-content
        input {
        width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 1310px) {
  .br-fulfillment-form__container {
    & .ant-form {
      & .ant-form-item {
        & .ant-row.ant-form-item-row {
          width: 268px;
        }
      }
    }
  }

  .br-fulfillment-form__first-section-container {
    padding-right: 0px;

    & .br-fulfillment-form__first-section-text-container {
      text-align: center;
      width: 100%;
      max-width: 648px;

      & h1 {
        font-size: 80px;
      }

      & h2 {
        font-size: 60px;
      }

      & svg {
        width: 500px;
        height: 60px;
      }
    }

    & .br-fulfillment-form__first-section-form-container {
      padding-top: 20px;

      & .ant-form-item {
        margin-bottom: 40px;
      }
    }
  }

  .br-fulfillment-form__container
    .br-fulfillment-form__radio-and-checkbox-container {
    gap: 90px;
    padding: 50px 100px;

    & .br-fulfillment-form__checkbox-container {
      & button.ant-btn {
        margin-top: 32px;
        width: 80%;
      }
    }

    & .ant-form-item {
      & .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        & .ant-checkbox-wrapper {
          margin: 0;
          margin-bottom: 8px;

          & .ant-checkbox-checked .ant-checkbox-inner::after {
            transform: rotate(150deg) scaleY(-1) scaleX(1) translate(-50%, -50%);
          }
        }
      }

      & .ant-col.ant-form-item-control {
        & .ant-radio-group {
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          & .ant-radio-wrapper {
            margin: 0;
            margin-bottom: 8px;
          }
        }
      }

      &
        .ant-row.ant-form-item-row
        .ant-col.ant-form-item-control
        .ant-form-item-control-input
        .ant-form-item-control-input-content
        input {
        width: 60%;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .br-fulfillment-form__container {
    & .ant-form {
      & .ant-form-item {
        & .ant-row.ant-form-item-row {
          width: 268px;
        }
      }
    }
  }

  .br-fulfillment-form__first-section-container {
    padding-right: 0px;
    flex-direction: column-reverse;
    padding: 0;
    justify-content: center;
    & .br-fulfillment-form__first-section-text-container {
      text-align: center;
      width: 100%;
      max-width: unset;
      padding-top: 200px;
      & h1 {
        font-size: 50px;
        line-height: 65px;
        margin-bottom: unset;
      }

      & h2 {
        font-size: 30px;
        line-height: unset;
        margin-bottom: unset;
      }

      & h4 {
        font-size: 20px;
      }

      & svg {
        width: 500px;
        height: 60px;
        display: none;
      }
    }

    & .br-fulfillment-form__first-section-form-container {
      padding-top: 0px;
      align-self: flex-start;
      padding: 0 40px;
      width: 100%;
      & .ant-form-item {
        width: 100%;
        & .ant-row.ant-form-item-row {
          flex-direction: column;
          align-items: flex-start;
          width: 70%;
        }
        margin-bottom: 20px;
      }
    }
  }

  .br-fulfillment-form__container
    .br-fulfillment-form__radio-and-checkbox-container {
    gap: 90px;
    padding: 50px 40px;

    & .br-fulfillment-form__checkbox-container {
      & button.ant-btn {
        margin-top: 32px;
        width: 80%;
      }
    }

    & .ant-form-item {
      & .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        & .ant-checkbox-wrapper {
          margin: 0;
          margin-bottom: 8px;

          & .ant-checkbox-checked .ant-checkbox-inner::after {
            transform: rotate(150deg) scaleY(-1) scaleX(1) translate(-50%, -50%);
          }
        }
      }

      & .ant-col.ant-form-item-control {
        & .ant-radio-group {
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          & .ant-radio-wrapper {
            margin: 0;
            margin-bottom: 8px;
          }
        }
      }

      &
        .ant-row.ant-form-item-row
        .ant-col.ant-form-item-control
        .ant-form-item-control-input
        .ant-form-item-control-input-content
        input {
        width: 60%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .br-fulfillment-form__first-section-container {
    padding-right: 0px;
    flex-direction: column-reverse;
    padding: 0;
    justify-content: center;

    & .br-fulfillment-form__first-section-text-container {
      text-align: center;
      width: 100%;
      max-width: unset;
      padding: 0 20px;
      padding-top: 150px;
      & h1 {
        font-size: 50px;
        line-height: unset;
        margin-bottom: unset;
      }

      & h2 {
        font-size: 30px;
        line-height: unset;
        margin-bottom: unset;
      }

      & h4 {
        font-size: 20px;
      }

      & svg {
        width: 500px;
        height: 60px;
        display: none;
      }
    }

    & .br-fulfillment-form__first-section-form-container {
      padding-top: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      padding: 0 20px;
      width: 100%;
      & .ant-form-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        & .ant-row.ant-form-item-row {
          flex-direction: column;
          align-items: flex-start;
          width: 70%;
        }

        margin-bottom: 20px;
      }
    }
  }

  .br-fulfillment-form__container
    .br-fulfillment-form__radio-and-checkbox-container {
    gap: 30px;
    padding: 50px 20px;
  }
}

@media only screen and (max-width: 575px) {
  .br-fulfillment-form__first-section-container {
    & .br-fulfillment-form__first-section-form-container {
      & .ant-form-item {
        width: 100%;
        display: flex;
        flex-direction: column;

        & .ant-row.ant-form-item-row {
          flex-direction: row;
        }
      }
    }
  }

  .br-fulfillment-form__container
    .br-fulfillment-form__radio-and-checkbox-container {
    gap: 10px;
    padding: 50px 20px;
    padding-bottom: 800px;
    flex-direction: column;
    & .br-fulfillment-form__checkbox-container {
      flex-direction: column;
      & button.ant-btn {
        margin-top: 32px;
        width: 80%;
      }
    }

    & .ant-form-item {
      margin-bottom: 0;
      & .ant-row.ant-form-item-row {
        flex-direction: inherit;
      }
      & .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        & .ant-checkbox-wrapper {
          margin: 0;
          margin-bottom: 8px;

          & .ant-checkbox-checked .ant-checkbox-inner::after {
            transform: rotate(150deg) scaleY(-1) scaleX(1) translate(-50%, -50%);
          }
        }
      }

      & .ant-col.ant-form-item-control {
        & .ant-radio-group {
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          & .ant-radio-wrapper {
            margin: 0;
            margin-bottom: 8px;
          }
        }
      }

      &
        .ant-row.ant-form-item-row
        .ant-col.ant-form-item-control
        .ant-form-item-control-input
        .ant-form-item-control-input-content
        input {
        width: 60%;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .br-fulfillment-form__first-section-container {
    & .br-fulfillment-form__first-section-text-container {
      padding-top: 250px;
    }
  }

  .br-fulfillment-form__container
    .br-fulfillment-form__radio-and-checkbox-container {
    gap: 10px;
    padding: 80px 20px;
    padding-bottom: 750px;
    flex-direction: column;

    & .br-fulfillment-form__checkbox-container {
      flex-direction: column;

      & button.ant-btn {
        margin-top: 32px;
        width: 80%;
      }
    }

    & .ant-form-item {
      & .ant-row.ant-form-item-row {
        flex-direction: inherit;
      }

      & .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        & .ant-checkbox-wrapper {
          margin: 0;
          margin-bottom: 8px;

          & .ant-checkbox-checked .ant-checkbox-inner::after {
            transform: rotate(150deg) scaleY(-1) scaleX(1) translate(-50%, -50%);
          }
        }
      }

      & .ant-col.ant-form-item-control {
        & .ant-radio-group {
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          & .ant-radio-wrapper {
            margin: 0;
            margin-bottom: 8px;
          }
        }
      }

      &
        .ant-row.ant-form-item-row
        .ant-col.ant-form-item-control
        .ant-form-item-control-input
        .ant-form-item-control-input-content
        input {
        width: 60%;
      }
    }
  }
}
